<template lang="pug">
	b-modal#modalRemove(ref='modal' hide-footer hide-header size='xs' :centered='!isMobile')
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross
		h2.modal-xs-title(v-if='item')
			span(v-if='item.canBeDeleted') {{ $t('plan.removePlan') }}
			span(v-else) {{ $t('plan.moveToArchive') }}

		.mb-4.lh-18(v-if='item')
			span(v-if='item.canBeDeleted') {{ $t('plan.titlePlan') }} {{ item.title }} {{ $t('plan.removePlanConfirm') }}
			span(v-else)
				| {{ $t('plan.archivePlanConfirm') }}&nbsp;
				br
				br
				| {{ item.title }} {{ $t('plan.archivePlanConfirmEnd') }}

		.d-flex.justify-content-between
			.btn.btn-border.btn-small.w-100.mr-4(@click="close") {{ $t('forms.cancel2') }}
			.btn.btn-peril.btn-small.w-100(v-if='item && item.canBeDeleted' @click='archivePlan' :class="{'disabled': isPending || isLoading}")
				.loader(v-if='isLoading')
				span(v-else) {{ $t('forms.remove') }}
			.btn.btn-peril.btn-small.w-100(v-else-if='item && !item.canBeDeleted' @click='archiveProject' :class="{'disabled': isPending || isLoading}")
				.loader(v-if='isLoading')
				span(v-else) {{ $t('forms.move') }}
</template>

<script>
import { ENV } from '@/api/index';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ModalRemovePlan',
    props: {
        item: {
            type: Object,
            default: () => null,
        },
    },
    data: () => ({
        isLoading: false,
    }),
    methods: {
        ...mapActions({
            remove: 'plans/remove',
            archive: 'plans/archive',
        }),

        removePlan() {
            this.isLoading = true;

            this.remove({ id: this.item.id })
                .then(() => {
                    this.$notify('error', this.$t('success.planRemove'));
                    this.$store.commit('plans/remove', this.item);
                    this.$store.commit('project/removePlan', this.item);
                    this.$store.commit('plans/edited', null);
                    this.$emit('success', true);

                    this.close();
                    this.isLoading = false;
                })
                .catch(v => {
                    this.$notify('error', this.$t('errors.planRemove'), v);
                    this.isLoading = false;
                });
        },

        archivePlan() {
            this.isLoading = true;

            this.archive({ id: this.item.id })
                .then(() => {
                    this.$notify('success', this.$t('success.planArchive'));
                    this.$store.commit('plans/archive', this.item);
                    this.$store.commit('project/editPlan', this.item);
                    this.$store.commit('plans/edited', null);
                    this.$emit('success', true);

                    this.close();
                    this.isLoading = false;
                })
                .catch(v => {
                    this.$notify('error', this.$t('errors.planArchive'), v);
                    this.isLoading = false;
                });
        },

        close() {
            this.$refs.modal.hide();
        },
    },
    computed: {
        ...mapGetters({
            isPending: 'plans/isPending',
        }),
    },
    watch: {
        item: {
            handler(nV, oV) {
                if (nV != null && nV.id) {
                    this.$refs.modal.show();
                }
            },
        },
    },
};
</script>
