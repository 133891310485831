<template lang="pug">
	b-row.row-plans
		b-colxx(xxs="12" v-for='(item, index) in sortedList' :key='index' :id='item.id' :class="{'mb-4': index != sortedList.length - 1}")
			b-card.card-plan(no-body :class="{'card-error' : item.getResources().length == 0}")
				b-alert.f-09.text-primary(v-if='item.isTrial' variant='primary' show) {{ $t('plan.free') }} {{ $t('plan.freePeriods.' + item.free.title) }}

				b-card-body.d-flex.justify-content-between.align-items-stretch.w-100(:class='{"pt-0" : item.isTrial}')
					.card-info
						p.card-title {{ item.title }}
						p.card-price
							span.text-primary(v-html="item.price.priceWithCurrency()")
							span.text-muted.text-nowrap.f-1 &nbsp;{{ $t('plan.periods.' + item.period.title) }}
						.resources-wrapper.d-flex.align-self-center.align-self-sm-end.flex-nowrap(:class='{"resources-wrapper-short" : short}' v-if='item.getResources().length != 0')
							.channel-image-wrapper(v-for='i in item.getResources()' :key='i.id')
								.channel-image(v-if='i.image' v-b-tooltip.hover :title='i.title')
									img.w-100.h-100(:src='i.image.contentUrl')
								.channel-image(v-else-if='i.title' v-b-tooltip.hover :title='i.title' :style='i.avatarBg')
									| {{ i.title.charAt(0) | uppercase }}
						.text-danger(v-else) {{ $t('plan.withEmptyResources') }}
					.icons-wrapper
						i.fontello.fontello-icon-edit(@click='edit(item)')
						i.fontello.fontello-icon-trash.ml-4(v-if='item && !item.isArchived()' @click='openModalRemove(item)')
</template>

<script>
import { bus } from '@/main.js';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Channel from '@/components/Common/Channel';

export default {
    name: 'PlansDataList',
    props: {
        status: String,
        short: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Channel,
    },
    data: () => ({}),

    mounted() {},
    created() {
        if (this.$route.query && this.$route.query.plan) {
            let p = _.find(this.items, i => i.id == this.$route.query.plan);

            if (p) this.$store.commit('plans/edited', p);
        }
    },

    destroyed() {},
    computed: {
        ...mapGetters({
            items: 'plans/list',
            plan: 'plans/edited',
        }),
        sortedList() {
            this.plan;
            let list = this.items;

            if (this.status && this.status == 'archived') {
                list = _.filter(this.items, i => i.isArchived());
            }

            if (this.status && this.status == 'active') {
                list = _.filter(this.items, i => i.isActive());
            }

            return this.items != null ? _.sortBy(list, l => Number(l.price.amount)) : [];
        },
    },
    methods: {
        ...mapActions({
            remove: 'plans/remove',
        }),
        edit(i) {
            this.$store.commit('plans/edited', null);
            this.$nextTick(() => this.$store.commit('plans/edited', i));
        },

        openModalRemove(i) {
            if (this.$route.name == 'project_plans') {
                return this.$emit('openModalRemove', i);
            }

            this.removeItem(i);
        },

        removeItem(i) {
            this.remove({ id: i.id })
                .then(() => {
                    this.$notify('error', this.$t('success.planRemove'));
                    this.$store.commit('plans/remove', i);
                    this.$store.commit('project/removePlan', i);
                    this.$store.commit('plans/edited', null);
                    this.$emit('success', true);
                })
                .catch(v => {
                    this.$notify('error', this.$t('errors.planRemove'), v);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.card-price {
}
.card-plan {
    & > .alert {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        padding: 8px;
        width: auto;
        margin-right: auto;
        margin-bottom: 8px;
    }
}
.icons-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;

    .fontello {
        color: var(--theme-color-1);
        cursor: pointer;
        font-size: 20px;
    }
}
.alert-primary {
    padding: 16px 22px;
}

.channel-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.channel-image-wrapper {
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
    }
}

@media (min-width: 768.01px) {
}

@media (max-width: 767.98px) {
    .icons-wrapper {
        align-self: flex-end;
        position: relative;
        top: 0 !important;
        right: auto;
    }

    .card-plan {
        & > .alert {
            border-bottom-right-radius: 0;
            padding: 10px;
            width: 100%;
            text-align: center;
            margin-right: auto;
            margin-bottom: 8px;
        }
    }
}
</style>
