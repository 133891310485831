<template lang="pug">
	dropdown-general(:list="dropdownList" :size="'large'" @onChange='changeStatus')
</template>

<script>
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import { STATUS_ACTIVE, STATUS_ARCHIVE } from '@/models/model';

export default {
    name: 'DropdownStatus',
    components: {
        DropdownGeneral,
    },

    data: () => ({
        dropdownList: [],
    }),
    created() {
        this.getDropdownList();
        this.changeStatus(this.dropdownList[0].id);
    },
    methods: {
        changeStatus(i) {
            this.$emit('onChange', i);
        },
        getDropdownList() {
            this.dropdownList = [
                {
                    id: STATUS_ACTIVE,
                    title: this.$t('promotions.dropdown[0]'),
                },
                {
                    id: STATUS_ARCHIVE,
                    title: this.$t('promotions.dropdown[1]'),
                },
            ];
        },
    },
};
</script>

<style lang="scss"></style>
