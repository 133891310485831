<template lang="pug">
	.d-flex.users-pics(v-if="list")
		.d-flex.justify-content-center.align-items-center.rounded-circle.users-pics__avatars(
			v-for="(item, i) in list"
			:key="`user-pic-${i}`"
			:class="{'cohesive': list.length > 3, 'cohesive-dark-mode': list.length > 3 && isDarkColor, 'brand-gradient': !item.avatar}"
			:title="item.title"
		)
			img(v-if="item.image && item.image.contentUrl" :src="item.image.contentUrl" alt="Аватар")
			span(v-else) {{ `${item.title}`.slice(0,1) }}
		.d-flex.justify-content-center.align-items-center.ml-3.b3.users-pics__amount.cursor-pointer(v-if="list.length > 4" :id='id' @click="isMobile ? $bvModal.show(id) : ''") +{{ list.length - 4 }}

		.modal-tooltip
			b-tooltip(v-if="!isMobile && list.length > 3" :target="id" no-fade custom-class='users-pics__tooltip' triggers="hover")
				.d-flex.flex-wrap.users-pics__wrap
					.d-flex.justify-content-center.align-items-center.users-pics__avatars.users-pics__avatars--tooltip(
						v-for="(item, i) in list"
						:id='`user-avatar-${card.id}-${item.id}`'
						:key="`user-pic-${i}`"
						:class="{'brand-gradient': !item.avatar}"
						:title="item.title"
					)
						img(v-if="item.image && item.image.contentUrl" :src="item.image.contentUrl")
						span(v-else) {{ `${item.title}`.slice(0,1) }}

			b-modal(v-if="isMobile" :id="id" content-class="modal-tooltip__modal" header-class="p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
				template(#modal-header="{ close }")
					h3.h3(v-html="$t('resources.addedResources')")
					icon-close(@click="close()")
				.d-flex.mb-4.flex-wrap.flex-gap-1
					.d-flex.justify-content-center.align-items-center.users-pics__mobile-avatars.users-pics__mobile-avatars--tooltip(
						v-for="(item, i) in list"
						:key="`user-pic-${i}`"
						:class="{'brand-gradient': !item.avatar}"
						:title="item.title"
					)
						img(v-if="item.image && item.image.contentUrl" :src="item.image.contentUrl")
						span(v-else) {{ `${item.title}`.slice(0,1) }}
				span.btn.btn-primary(@click="$bvModal.hide(id)") {{ $t('resources.btnFine') }}
</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'UsersPics',
    components: {
        IconClose,
    },
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        card: {
            default: () => {},
            type: Object,
        },
    },
    computed: {
        id() {
            return this.card ? `tooltip-users-list-${this.card.id}` : '';
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.users-pics {
    margin-top: 17px;
    margin-left: -2.5px;
    margin-bottom: -2px;

    &__mobile-avatars {
        display: none !important;
        width: 54px;
        min-width: 54px;
        height: 54px;
        min-height: 54px;
        font-size: 17px;
        line-height: 14px;
        text-transform: uppercase;
        color: var(--foreground-color);
        border: 3px solid var(--foreground-color);
        margin-left: -5px;

        img {
            display: block;
            border-radius: 40px;
            width: 100%;
            height: 100%;
        }

        &:nth-child(-n + 4) {
            display: flex !important;
        }

        &.cohesive {
            margin-left: -15px;
        }

        &.brand-gradient {
            background: var(--brand-gradient-primary, linear-gradient(227deg, #0071f4 0%, #66b4ff 100%));
        }

        &--tooltip {
            display: flex !important;
            border-radius: 50%;
        }
    }
    &__avatars {
        display: none !important;
        width: 54px;
        min-width: 54px;
        height: 54px;
        min-height: 54px;
        font-size: 17px;
        line-height: 14px;
        text-transform: uppercase;
        color: var(--foreground-color);
        border: 3px solid var(--foreground-color);
        margin-left: -5px;

        img {
            display: block;
            border-radius: 40px;
            width: 100%;
            height: 100%;
        }

        &:nth-child(-n + 4) {
            display: flex !important;
        }

        &:first-child {
            margin-left: 0;
        }

        &.cohesive {
            margin-left: -15px;

            &:first-child {
                margin-left: 0;
            }
        }

        &.brand-gradient {
            background: var(--brand-gradient-primary, linear-gradient(227deg, #0071f4 0%, #66b4ff 100%));
        }

        &--tooltip {
            display: flex !important;
            margin: 5px;
            border: none;
            border-radius: 50%;

            &:first-child {
                margin-left: 5px;
            }
        }
    }

    &__amount {
        color: var(--primary-text-color);
    }

    &__wrap {
        width: 192px;
    }

    &__tooltip {
        .tooltip-inner {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 12px 8px !important;
            width: 220px !important;
        }
    }
}
</style>
