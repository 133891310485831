<template lang="pug">
	.mb-4.d-flex.flex-wrap.justify-content-between.promo-codes(v-if='list && list.length > 0')
		.d-flex.justify-content-between.align-items-center.w-100.promo-codes__header
			dropdown-status(@onChange='changeStatus')
			.btn.btn-primary.plus-primary.promo-codes__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('promotions.promoCodes.createEvent.btn') }}
		promotions-card.mb-4(v-for="(card, i) in listFiltered" :card="card" :key="card.id" :promotions="types.promoCode" @editPromocode="$emit('editPromocode')" :customClass="card.isArchived() ? 'popover-resources is-archive' : 'popover-resources is-promocodes'")

		.d-flex.flex-column.w-100.promo-codes__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center 😢
			span.b1.text-center.promo-codes__txt {{ $t('project.notArchivedPromo') }}

		.d-flex.flex-column.w-100.promo-codes__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center 😢
			span.b1.text-center.promo-codes__txt {{ $t('project.notActivedPromo') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstPromo') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import { STATUS_ACTIVE } from '@/models/model';
import { mapGetters } from 'vuex';
import { PLAN_TABS } from '@/models/project';

export default {
    name: 'PromoCodes',
    components: {
        DropdownStatus,
        PromotionsCard,
    },
    data: () => ({
        selectedStatus: null,
        types: PLAN_TABS,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            list: 'project/promocodes',
        }),

        listFiltered() {
            return _.filter(this.list, i => (this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived()));
        },
    },
    methods: {
        changeStatus(i) {
            this.selectedStatus = i;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.promo-codes {
    &__header {
        margin-bottom: 20px;
    }

    &__btn {
        min-width: 223px;

        @include respond-below(sm-new) {
            min-width: 50px;
            min-height: 50px;

            .plus-primary {
                margin: 0;
            }
        }
    }

    &__wrap {
        margin-top: 165px;

        @include respond-below(sm-new) {
            margin-top: 132px;
        }
    }

    &__txt {
        margin-top: 11px;
        margin-bottom: 11px;
    }
}
</style>
