\
<template lang="pug">
	b-card.card-channel(no-body :class='{"card-error": item.isBotRemoved() || item.hasBotIssue(), "card-success" : !item.isBotRemoved()}')
		b-alert.f-09.mb-0(:variant='item.isBotRemoved() || item.hasBotIssue() ? "danger" : "success"' :class='item.isBotRemoved() || item.hasBotIssue() ? "text-danger" : "text-success"' show)
			span(v-if='item.isBotRemoved()') {{ $t('bot.disconnected') }}
			span(v-else-if='item.hasBotIssue()') {{ $t('bot.hasNotAccess') }}
			span(v-else) {{ $t('bot.connected') }}

		b-card-body
			.d-flex.flex-sm-wrap.align-items-center
				img.card-channel--img(:src='item.image.contentUrl' v-if='item.image')
				.card-channel--img-default(v-else-if='item.title' :style='item.avatarBg') {{ item.title.charAt(0) | uppercase }}
				.flex-grow-1
					h3.mb-0 {{ item.title }}
					ul.ul-dots.mb-0.mt-2(v-if='hasErrors')
						li.text-danger(v-if='connectedToPlans.length == 0') {{ $t('bot.withoutResource') }}

				i.text-primary.fontello.fontello-icon-trash.cursor-pointer(@click='openModalRemove(item)')
		b-card-footer(v-if='hasErrors')
			p.mb-0(v-if='hasErrors')
				span(v-if='connectedToPlans.length == 0')
					| {{ $t('bot.resourceWithoutPlan1') }}&nbsp;
					span.text-primary.cursor-pointer(@click='goToTariffs') {{ $t('project.tariffs') }}
					| &nbsp; {{ $t('bot.resourceWithoutPlan2') }}
				span.lh-20(v-else-if='item.isChannel()' v-html="$t('bot.resourceAccessIssue2')")
				span.lh-20(v-else v-html="$t('bot.resourceAccessIssue3')")
			.mt-3(v-if='connectedToPlans.length != 0')
				b-btn(variant='outline-primary' size='sm' @click='checkAccess' ) {{ $t('bot.doCheckAccess') }}
		b-card-footer.card-footer-tiny(v-else)
			.position-relative.cursor-pointer.list-item-heading(v-b-toggle="'collapseProjects' + item.id") {{ $t('bot.connectedTo')}} {{ connectedToPlansDesc }}
			b-collapse(:id='`collapseProjects${item.id}`')
				ul.mt-2.mb-0.ul-dots.ul-dots-small.ul-dots-text-small
					li(v-for='i in connectedToPlans' :key='i.id') {{ i.title }}

</template>

<script>
import Tg from '@/components/Icons/tg';
import Ig from '@/components/Icons/ig';
import { mapActions, mapGetters } from 'vuex';
import { SERVICE_STRING } from '@/utils/string';

export default {
    name: 'Channel',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        isPending: false,
    }),
    components: {
        'icon-tg': Tg,
        'icon-ig': Ig,
    },

    created() {},

    methods: {
        ...mapActions({
            getChannel: 'resources/channel_check',
            getGroup: 'resources/group_check',
        }),

        openModalRemove() {
            this.$store.commit('resources/openModalRemove', this.item);
        },

        checkAccess() {
            let method = this.getChannel;

            if (this.item.isGroup()) method = this.getGroup;

            method(this.item.id)
                .then(v => {
                    if (v.hasBotIssue()) this.$notify('error', `😢 &nbsp;&nbsp;&nbsp;${this.$t('bot.disconnected')}`);
                    else {
                        this.$store.commit('project/updateResource', v);

                        if (v.isChannel()) this.$store.commit('plans/editChannel', v);
                        if (v.isGroup()) this.$store.commit('plans/editGroup', v);

                        this.$store.commit('resources/edit', v);

                        this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('bot.connected')}`);
                    }
                })
                .catch(v => this.$notify('error', v));
        },
        goToTariffs() {
            this.$emit('onGoToTariffs', true);
        },
    },

    computed: {
        ...mapGetters({
            plans: 'plans/list',
        }),
        hasErrors() {
            return (
                this.item && (this.item.hasBotIssue() || this.item.isBotRemoved() || this.connectedToPlans.length == 0)
            );
        },

        connectedToPlans() {
            let plans = [];

            if (!this.item) return true;

            _.each(this.plans, p => {
                if (this.item.isGroup())
                    _.each(p.groups, g => {
                        if (g.id == this.item.id) {
                            plans.push(p);
                        }
                    });

                if (this.item.isChannel())
                    _.each(p.channels, c => {
                        if (c.id == this.item.id) {
                            plans.push(p);
                        }
                    });
            });

            return plans;
        },

        connectedToPlansDesc() {
            return (
                this.connectedToPlans.length +
                ' ' +
                SERVICE_STRING.ru_ends(this.connectedToPlans.length, this.$t('project.plansEnds'))
            );
        },
    },
    watch: {
        item: {
            handler(nv) {},
            deep: true,
        },
    },
};
</script>

<style scoped lang="scss">
.card-channel {
    & > .alert {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 10px;
        text-align: center;
    }

    .card-body {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .card-footer {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}
.card-channel--img,
.card-channel--img-default {
    --imgSize: 50px;

    width: var(--imgSize);
    height: var(--imgSize);
    border-radius: 50%;
    margin-right: 16px;
}

.card-channel--img-default {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-channel--bot-status {
    list-style: none;
    padding-left: 0;

    li:before {
        content: ' ';
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 4px;
        vertical-align: 1px;
    }

    li.text-success:before {
        background: var(--success-color);
    }

    li.text-danger:before {
        background: var(--error-color);
    }
}

.list-item-heading::after {
    top: 0;
    right: 5px;
}

.card-footer-tiny {
    padding-top: 14px;
    padding-bottom: 14px;
}
</style>
